/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link, HashRouter, Redirect} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'

import {Routes} from '../../../../app/routing/Routes'
import {mainConfig} from '../../../../../src/config/mainConfig'

let token = ''

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [toLogout, setToLogout] = useState(false)
  const [tokens, setToken] = useState<any>('')

  useEffect(() => {
    token = JSON.parse(localStorage.getItem('portalUserDataToken') || '')
    setToken(token)
  }, [])

  const logout = () => {
    fetch(`${mainConfig.MAIN_ENDPOINT}/api/logout`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        "header":"Access-Control-Allow-Origin: *"
      },
      
    })
      .then(function (data) {
        data.json().then(function (data) {
          if (data) {
           
            setToLogout(true)
            localStorage.clear();
            window.location.reload();
            // <Redirect exact to='/' />

          
           
             
           
          }
        })
      })
      .catch(function (error) {})
  }

  return toLogout == true ? (
    <HashRouter>
      <Routes />
    </HashRouter>
  ) : (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
