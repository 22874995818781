import React, {FC, useEffect, useRef, useState} from 'react'
import {SearchComponent} from '../../../assets/ts/components'
import {KTSVG} from '../../../helpers'
import {mainConfig} from '../../../../../src/config/mainConfig'
import {Link} from 'react-router-dom'

const Search: FC = (props) => {
  let token = ''
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const [serched, setSearched] = useState<any[]>([])

  const processs = (search: SearchComponent) => {
    const myTimeOut = setTimeout(function () {
      const number = Math.floor(Math.random() * 6) + 1

      // Hide recently viewed
      suggestionsElement.current!.classList.add('d-none')

      if (number === 3) {
        // Hide results
        resultsElement.current!.classList.add('d-none')
        // Show empty message
        emptyElement.current!.classList.remove('d-none')
      } else {
        // Show results
        resultsElement.current!.classList.remove('d-none')
        // Hide empty message
        emptyElement.current!.classList.add('d-none')
      }

      // Complete search
      search.complete()
    }, 1500)
    clearTimeout(myTimeOut)
  }

  const clear = (search: SearchComponent) => {
    // Show recently viewed
    suggestionsElement.current!.classList.remove('d-none')
    // Hide results
    resultsElement.current!.classList.add('d-none')
    // Hide empty message
    emptyElement.current!.classList.add('d-none')
  }

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')

    // Search handler
    searchObject!.on('kt.search.process', processs)

    // Clear handler
    searchObject!.on('kt.search.clear', clear)
  }, [serched])

  const onSearchProduct = (e: any) => {
    let n = e.target.value

    let searchdata = new Array()

    if (n == '' || n == 0) {
      setSearched([])
    } else {
      try {
        fetch(`${mainConfig.MAIN_ENDPOINT}/api/search-product/${n}`, {
          method: 'get',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
          .then(function (response) {
            if (response.ok == true) {
              response.json().then(function (data) {
                setSearched(data.data)
              })
            } else {
              setSearched([])
            }
          })
          .catch(function (err) {
            setSearched([])
          })
      } catch (err) {}
    }
  }

  token = JSON.parse(localStorage.getItem('portalUserDataToken') || '')
  let url = window.location.href
  let result = url.includes('homes')

  return (
    <React.Fragment>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px mt-4'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px searchPosition'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              data-kt-search-element='form'
              className='w-100 position-relative mb-3'
              autoComplete='off'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                placeholder='Search by product'
                data-kt-search-element='input'
                onChange={onSearchProduct}
              />
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                  Products
                </h3>

                {serched && serched.length > 0 && (
                  <React.Fragment>
                    {serched.map((j: any) => {
                      return (
                        <a
                          href='/#'
                          className='d-flex text-dark text-hover-primary align-items-center mb-5'
                        >
                          <div className='symbol symbol-40px me-4'></div>

                          <div className='d-flex flex-column justify-content-start fw-bold'>
                            <Link to={{pathname: '/product/#/', state: j}} tabIndex={-1}>
                              <button type='button' className='btn btn-md btn-light-primary t-3'>
                                {j.parent_product_code} &nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                {j.clean_description}
                              </button>
                            </Link>
                          </div>
                        </a>
                      )
                    })}
                  </React.Fragment>
                )}
              </div>
            </div>

            <div className='mb-4' data-kt-search-element='main'>
              <div className='scroll-y mh-200px mh-lg-325px'>
                {serched && serched.length > 0 && (
                  <React.Fragment>
                    {serched.map((j: any) => {
                      return (
                        <a
                          href='/#'
                          className='d-flex text-dark text-hover-primary align-items-center mb-5'
                        >
                          <div className='symbol symbol-40px me-4'></div>

                          <div className='d-flex flex-column justify-content-start fw-bold'>
                            <div className='flex-grow-1'>
                              <Link
                                to={{pathname: '/product', state: j}}
                                tabIndex={-1}
                                onClick={(e: any) => {
                                  setSearched([])
                                }}
                              >
                                <button
                                  type='button'
                                  className='btn btn-sm btn-light-primary t-3 fs-9'
                                >
                                  {j.parent_product_code} &nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                  {j.clean_description}
                                </button>
                              </Link>
                            </div>
                          </div>
                        </a>
                      )
                    })}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export {Search}
