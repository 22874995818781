import { useState, useEffect} from 'react'
import { toAbsoluteUrl} from '../../../helpers'
import {mainConfig} from '../../../../../src/config/mainConfig'
import {timeAgo} from "../../../../../src/app/utils/TimeAgo"
import { CloseOutlined  } from '@ant-design/icons';
import { Button,Spin } from 'antd';

let token = ''
const HeaderNotificationsMenu = (props :any)  => {
  const [flag,setflag]=useState<any>(false)
  const [loader,setLoader]=useState<any>(false)
  // useEffect(() => {
   
  // }, [flag])

  token = JSON.parse(localStorage.getItem('portalUserDataToken') || '')
const deleteDML=(alert:any)=>{
  setLoader(true)
  fetch(`${mainConfig.MAIN_ENDPOINT}/api/activity-logs/${alert.id}/mark-as-read`, {

    method: 'put',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(function (response) {
      if (response.status != 200) {
        return
      }
      response.json().then(function (data) {
        props.updateData()
        setflag(!flag)
        setLoader(false)
      })
    })
    .catch(function (err) {})
}
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-500px w-lg-500px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column  rounded-top '
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`,backgroundRepeat:"repeat-x"}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Notifications <span className='fs-8 opacity-75 ps-3'></span>
        </h3>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
        <div className='scroll-y mh-400px my-5 px-8'>
        <>
        { props.loader &&   <div className='d-flex justify-content-center'> <Spin /></div>}
            {props.loader == false && props.NotifData.map((alert :any, index:any) => (
              <div key={`alert${index}`} className='d-flex flex-stack py-4'>
               <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-4'></div>
                  <div className='mb-0 me-2'>
                    <span className='fs-6 text-gray-800 fw-bolder'>
                      {
                        alert.description
                      }
                    </span>
                  
                  </div>
                </div>
                <span className='badge badge-light fs-8'>
                  {
                    timeAgo(alert.created_at)
                  }
                </span>
                <Button shape="circle" icon={<CloseOutlined />} onClick={(e)=>{
                  deleteDML(alert)
                }} />
              </div>
            ))}
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}




